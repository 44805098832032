import { Injectable } from '@angular/core';
import { TenantApiService } from '@api/tenant/tenant.api.service';
import { Observable, of } from 'rxjs';
import { WindowService } from '@services/window.service';
import { Constants } from '@utils/Constants';
import { catchError } from 'rxjs/operators';
import { Tenant } from '../../types/Tenant';
import { ActionResponse } from '../../types/DataResult';
import { TenantSSOOptions } from '../../types/login/SSO';

@Injectable()
export class TenantRepository {
  public constructor(
    public readonly tenantApi: TenantApiService,
    public windowService: WindowService,
  ) {}

  public getTenantLogo(): Observable<string> {
    const subdomain = this.windowService.getSubdomain();

    if (!subdomain) {
      return of(Constants.MASTER_TENANT_LOGO);
    }

    return this.tenantApi.getTenantLogo(subdomain).pipe(catchError(() => of(Constants.NO_IMAGE)));
  }

  public getTenantOrganization(): Observable<Tenant> {
    return this.tenantApi.getTenantOrganization();
  }

  public uploadLoginImage(file: File): Observable<void> {
    return this.tenantApi.uploadLoginImage(file);
  }

  public deleteLoginImage(): Observable<void> {
    return this.tenantApi.deleteLoginImage();
  }

  public updateTenantOrganization(tenant: Tenant): Observable<ActionResponse> {
    return this.tenantApi.updateTenantOrganization(tenant);
  }

  public getTenantSSOOptions(): Observable<TenantSSOOptions> {
    return this.tenantApi.getTenantSSOOptions();
  }
}
