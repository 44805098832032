import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { processImage } from '@api/utils/process-image.util';
import { buildUrl } from '@utils/url/url.util';
import { RESTServerRoute } from '../../types/Server';
import { TenantSSOOptions } from '../../types/login/SSO';
import { Tenant } from '../../types/Tenant';
import { ActionResponse } from '../../types/DataResult';

@Injectable()
export class TenantApiService {
  public constructor(private httpClient: HttpClient) {}

  public getTenantLogo(subdomain: string): Observable<string> {
    const params = { Subdomain: subdomain };
    return this.httpClient
      .get<Blob>(RESTServerRoute.REST_TENANT_LOGO, {
        responseType: 'blob' as 'json',
        params,
      })
      .pipe(switchMap((blob: Blob) => processImage(blob)));
  }

  public uploadLoginImage(file: File): Observable<void> {
    const formData = new FormData();
    formData.append('image', file, file.name);

    return this.httpClient.post<void>(RESTServerRoute.REST_TENANT_BACKGROUND_IMAGE, formData);
  }

  public deleteLoginImage(): Observable<void> {
    return this.httpClient.delete<void>(RESTServerRoute.REST_TENANT_BACKGROUND_IMAGE);
  }

  public getTenantSSOOptions(): Observable<TenantSSOOptions> {
    return this.httpClient.get<TenantSSOOptions>(RESTServerRoute.REST_TENANT_SSO_OPTIONS);
  }

  public getTenantOrganization(): Observable<Tenant> {
    return this.httpClient.get<Tenant>(RESTServerRoute.REST_TENANT_ORGANIZATION);
  }

  public getTenantDataByTenantId(tenantId: string): Observable<Tenant> {
    return this.httpClient.get<Tenant>(buildUrl(RESTServerRoute.REST_TENANT, { id: tenantId }));
  }

  public updateTenantOrganization(tenant: Tenant): Observable<ActionResponse> {
    return this.httpClient.put<ActionResponse>(RESTServerRoute.REST_TENANT_ORGANIZATION, tenant);
  }

  public updateTenantById(tenant: Tenant): Observable<ActionResponse> {
    return this.httpClient.put<ActionResponse>(buildUrl(RESTServerRoute.REST_TENANT, { id: tenant.id }), tenant);
  }

  public deleteTenant(tenantId: string): Observable<ActionResponse> {
    return this.httpClient.delete<ActionResponse>(buildUrl(RESTServerRoute.REST_TENANT, { id: tenantId }));
  }

  public createTenant(tenant: Tenant): Observable<ActionResponse> {
    return this.httpClient.post<ActionResponse>(RESTServerRoute.REST_TENANTS, tenant);
  }
}
