import { Observable, of } from 'rxjs';

export function processImage(blob: Blob): Observable<string> {
  if (blob.size > 0) {
    return new Observable((observer) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); // convert blob to base64
      reader.onloadend = () => {
        observer.next(reader.result?.toString()); // emit the base64 string result
      };
    });
  }
  return of(null);
}
